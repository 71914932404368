@import "variables";
@import '~@ionic/angular/css/core.css';


body {
  font-size: 16px;
  background-color: white;
  color: black;
  --ion-background-color: transparent;
  @media all and (min-width: 768px) {
    background-color: var(--light-grey);
  }
}

.ion-page {
  top: calc(env(safe-area-inset-top)/3);
}

main {
  padding-top: 112px;
  background-color: white;

  @media all and (min-width: 768px) {
    padding: 112px 20px 76px 20px;
    background-color: transparent;
  }
}

.has-link {
  main {
    padding-top: 127px;

    @media all and (min-width: 768px) {
      padding: 112px 20px 76px 20px;
    }
  }
}

h1 {
  font-size: 30px;
  font-weight: bold !important;
  line-height: 1.3;
}

h3 {
  font-family: Roboto;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 26px !important;

  @media all and (min-width: 768px) {
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
  }
}

h4 {
  text-transform: uppercase;
  margin-top: 20px !important;
  margin-bottom: 10px;
  font-size: 16px !important;
  font-weight: 600 !important;

  & + p {
    margin-top: 20px;
  }
}

.tiny-light {
  font-size: 12px;
  line-height: 1.5;
  color: #0009;
}

a {
  font-size: 14px;
  line-height: 20px;
  color: black;
  text-decoration: none;
  border-bottom: 1.5px solid var(--blue);

  &:hover {
    color: var(--blue);
  }
}

button + button {
  margin-left: 10px;
}

ion-datetime-button {
  justify-content: flex-start;
  display: inline-flex;
  border: 1px solid var(--mdc-outlined-text-field-outline-color);
  border-radius: var(--mdc-outlined-text-field-container-shape);
  height: 56px;

  --ion-color-step-300: transparent;
}

.datetime {
  position: relative;

  mat-label {
    position: absolute;
    top: -8px;
    left: 12px;
    background: white;
    padding: 0 2px;
    font-size: 12px;
  }
}

.ion-datetime-button-overlay {
  --width: fit-content;
  --height: fit-content;
}

ion-refresher.refresher-active {
  top: 116px;
  display: unset;
  z-index: unset;
}

.hide-on-mobile {
  display: none !important;

  @media all and (min-width: 768px) {
    display: block !important;
  }
}

.hide-on-mobile-flex {
  display: none !important;

  @media all and (min-width: 768px) {
    display: flex !important;
  }
}

.hide-on-desktop {
  display: block !important;

  @media all and (min-width: 768px) {
    display: none !important;
  }
}

.hide-on-desktop-flex {
  display: flex !important;

  @media all and (min-width: 768px) {
    display: none !important;
  }
}

.hide-on-desktop-inline-flex {
  display: inline-flex !important;

  @media all and (min-width: 768px) {
    display: none !important;
  }
}

app-header h3 {
  margin: 0;
  margin-left: 20px;
}

.tabs {
  margin-top: 6px;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-left: 20px;

  a {
    margin-top: 0;
    border: none;
    white-space: nowrap;
    margin-left: 21px;

    @media all and (min-width: 768px) {
      margin-left: 30px;
    }

    @media all and (max-width: 896px) {
      margin-left: 18px;
    }

    &:first-child {
      margin-left: 0;
    }

    &.active {
      color: var(--blue);
      font-weight: 800;
    }
  }
}


.card {
  background-color: white;
  width: 100vw;
  padding: 20px 20px 0 20px;
  overflow-y: hidden;

  @media all and (min-width: 768px) {
    width: 100%;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;

    &.full-height {
      align-self: stretch;
    }

    &:nth-child(even) {
      margin-left: 20px;
    }

    &:nth-child(n + 3) {
      margin-top: 20px;
    }
  }

  .card-header {
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
    }

    mat-icon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      color: var(--blue);
    }

    .count {
      margin-left: 8px;
    }
  }
}

.cards {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;

  @media all and (min-width: 768px) {
    margin-top: 40px;
  }

  & + .button-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .card {
    flex: 0 0 100%;

    @media all and (min-width: 768px) {
      flex: 0 0 calc(50%  - 10px);

      &.full-width {
        flex: 0 0 100%;
      }

      &:first-child:last-child {
        flex: 0 0 100%;
      }
    }
  }
}

.intro {
  @media all and (min-width: 768px) {
    padding-top: 30px;
  }
}

.count {
  display: inline-flex;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0000001A;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  color: var(--dark-grey);
  font-weight: 870;
  padding: 2px;
}

.footer-nav {
  bottom: 0;
  width: 100vw;
  border-top: 1px solid #0000001A;
  background-color: white;

  a {
    flex: 0 0 33.3%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 75px;
    font-size: 10px;
    line-height: 20px;

    &.active {
      background: #0095C31A;
      color: var(--blue);
    }

    mat-icon {
      width: 24px;
      height: 24px;
      margin-bottom: 5px;
    }
  }

  &.button-row {
    padding: 10px 20px 20px 20px;
    border-top: none;
    display: flex;
    justify-content: flex-end;

    & + .footer-nav.button-row {
      padding-bottom: 0;
      bottom: 74px;
    }
  }

}

.footer-button {
  position: fixed;
  bottom: 76px;
  width: 100%;
  background-color: white;
  height: 64px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row-reverse;
  column-gap: 10px;
  @media all and (min-width: 768px) {
    position: unset;
    background-color: unset;
  }

  button {
    position: relative;
    @media all and (min-width: 768px) {
      bottom: unset;
    }
  }

}

.button-nav {
  position: fixed;
  bottom: 95px;
  right: 20px;
}

.grid-parent-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 10px;

  .grid-full {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}


.grid-parent-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 12px;
}

.grid-1 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.grid-2 {
  grid-column-start: 2;
  grid-column-end: 3;
}
.grid-3 {
  grid-column-start: 3;
  grid-column-end: 4;
}
.grid-4 {
  grid-column-start: 4;
  grid-column-end: 5;
}
.grid-5 {
  grid-column-start: 5;
  grid-column-end: 6;
}
.grid-6 {
  grid-column-start: 6;
  grid-column-end: 7;
}
.grid-1-2 {
  grid-column-start: 1;
  grid-column-end: 3;
}
.grid-1-3 {
  grid-column-start: 1;
  grid-column-end: 4;
}
.grid-1-4 {
  grid-column-start: 1;
  grid-column-end: 5;
}
.grid-1-5 {
  grid-column-start: 1;
  grid-column-end: 6;
}
.grid-1-6 {
  grid-column-start: 1;
  grid-column-end: 7;
}
.grid-2-3 {
  grid-column-start: 2;
  grid-column-end: 4;
}
.grid-2-4 {
  grid-column-start: 2;
  grid-column-end: 5;
}
.grid-2-5 {
  grid-column-start: 2;
  grid-column-end: 6;
}
.grid-2-6 {
  grid-column-start: 2;
  grid-column-end: 7;
}
.grid-3-4 {
  grid-column-start: 3;
  grid-column-end: 5;
}
.grid-3-5 {
  grid-column-start: 3;
  grid-column-end: 6;
}
.grid-3-6 {
  grid-column-start: 3;
  grid-column-end: 7;
}
.grid-4-5 {
  grid-column-start: 4;
  grid-column-end: 6;
}
.grid-4-6 {
  grid-column-start: 4;
  grid-column-end: 7;
}
.grid-5-6 {
  grid-column-start: 5;
  grid-column-end: 7;
}

.grid-full {
  grid-column-start: 1;
  grid-column-end: 5;
}


@mixin grid-columns-6 {
  &.grid-1-2 {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &.grid-1-3 {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  &.grid-1-5 {
    grid-column-start: 1;
    grid-column-end: 6;
  }

  &.grid-2-3 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  &.grid-3-4 {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  &.grid-3-5 {
    grid-column-start: 3;
    grid-column-end: 6;
  }

  &.grid-2-6 {
    grid-column-start: 2;
    grid-column-end: 7;
  }

  &.grid-3-6 {
    grid-column-start: 3;
    grid-column-end: 7;
  }

  &.grid-4-6 {
    grid-column-start: 4;
    grid-column-end: 7;
  }

  &.grid-4-5 {
    grid-column-start: 4;
    grid-column-end: 6;
  }

  &.grid-5-6 {
    grid-column-start: 5;
    grid-column-end: 7;
  }
}

.small-page-container {
  background-color: white;
  .small-page {
    width: 100%;

    @media all and (min-width: 768px) {
      width: 768px;
      margin: 0 auto;
    }

    .mobile-full-else-right {
      grid-column-start: 1;
      grid-column-end: 5;
      text-align: center;

      @media all and (min-width: 768px) {
        text-align: end;
      }
    }
    button.mobile-full-else-right {
      @media all and (min-width: 768px) {
        grid-column-start: 4;
      }
    }
    ion-spinner.mobile-full-else-right {
      @media all and (min-width: 768px) {
        grid-column-start: 4;
        margin: auto
      }
    }
  }
}

.og-logo {
  width: 50%;
}
.mat-datepicker-content {
  background-color: white;
}

.grid-parent-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 20px;
  row-gap: 12px;

  & > * {
    @include grid-columns-6;
  }

  .grid-full {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .grid-full-mobile {
    grid-column-start: 1;
    grid-column-end: 7;

    @media all and (min-width: 768px) {
      @include grid-columns-6;
    }
  }
}


.mat-mdc-dialog-content ngx-mat-timepicker-face .clock-face {
  background-color: #e6e6e6 !important;
}

html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: var(--dark-grey);
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: black !important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: black !important;
}

.fake-form-field {
  margin: 4px 0;
  border-radius: 5px;
  background: #F0F0F066;
  position: relative;
  padding: 16px;
  color: black;

  label {
    position: absolute;
    top: -12px;
    font-size: 12px;
    font-weight: 500;
  }
}

.fullsize-dialog.cdk-overlay-pane.mat-mdc-dialog-panel {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh;

  @media all and (min-width: 768px) {
    max-width: var(--mat-dialog-container-max-width, 80vw) !important;
    width: auto !important;
    height: auto;
  }

  .mat-mdc-dialog-content {
    max-height: 100vh !important;

    @media all and (min-width: 768px) {
      max-height: 65vh !important;
    }
  }
}

.back-link {
  display: inline-flex;
  align-items: center;
  border-bottom: none;
  margin: 8px 20px;

  span {
    border-bottom: 1.5px solid var(--blue);
  }

  mat-icon {
    width: 14px;
    height: 14px;
    color: var(--blue);
    margin-right: 5px;
  }
}

.error-text {
  color: red;
}


.load-more {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 20px 0 10px 0;

  a {
    display: inline-flex;
    align-items: center;
    border-bottom: none;
    cursor: pointer;

    span {
      border-bottom: 1.5px solid var(--blue);
    }

    mat-icon {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }
}

.availability-link {
  position: relative;
}

.embedded-info {
  position: absolute;
  right: 40%;
  width: 15px !important;
}

.mat-mdc-table {
  padding: 10px 0;
  border-radius: 10px;
  .mat-mdc-row, .mat-mdc-header-row {
    padding: 0 24px;
    .mat-mdc-cell, .mat-mdc-header-cell {
      padding: 4px 8px;
    }
    :first-child {
      &.mat-mdc-cell, &.mat-mdc-header-cell {
        padding-left: 0;
      }
    }
    :last-child {
      &.mat-mdc-cell, &.mat-mdc-header-cell {
        padding-right: 0;
      }
    }
  }
  .mat-mdc-cell {
    color: #0009;
    font-weight: 500;

    mat-icon {
      color: #565657;
    }
  }
  .mat-mdc-header-cell {
    color: #565657;
    font-weight: 500;
  }

  .mat-mdc-row.inactive {
    .mat-mdc-cell {
      &:not(.mat-column-actions) {
        opacity: 50%;
      }
    }
  }
}

.multi-line-cell {
  display: flex;
  flex-direction: column;
  span {
    width: 100%;
  }
  .bold {
    font-weight: 500;
    color: black;
  }
}

.table-footer {
  margin-top: 40px;
}

.invisible {
  visibility: hidden;
}

.button-wrapper-right-aligned {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.cancelled-chip {
  .mdc-evolution-chip-set__chips {
    justify-content: right;
    mat-chip {
      background-color: #f7d8d1;
      .mdc-evolution-chip__text-label {
        color: #d93c1a;
      }
    }
  }
}

.right-and-left-wrapper {
  display: flex;
  justify-content: space-between;
}

.multi-line-hint {
  .mat-mdc-form-field-subscript-wrapper {
    height: 48px;
  }
}

/* hide arrow buttons in number inputs for Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.archive-sort-header {
  display: flex;

  .archive-sort-header-label {
    color: grey;
    width: 110px;
    min-width: 110px;
    max-width: 110px;
  }
}
