:root {
  // Form field
  --mdc-outlined-text-field-hover-outline-color: var(--anthracite);
  --mdc-outlined-text-field-focused-outline-color: var(--anthracite);
  --mdc-outlined-text-field-container-shape: 5px;
  --mdc-outlined-text-field-disabled-outline-color: #F0F0F066;

  // Button
  --mat-filled-button-horizontal-padding: 24px;
  --mdc-filled-button-container-height: 44px;
  --mdc-filled-button-container-shape: 10px;
  --mdc-filled-button-label-text-tracking: 0;

  // Snackbar
  --mdc-snackbar-container-color: var(--dark-grey);
  --mdc-snackbar-supporting-text-weight: 500;
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: black !important;
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  letter-spacing: 0 !important;
}

.mdc-floating-label {
  color: var(--dark-grey) !important;

  &.mdc-floating-label--float-above {
    color: black !important;
  }
}

.mat-mdc-form-field-hint {
  color: var(--anthracite);
}

.single-line-hint {
  .mat-mdc-form-field-hint {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 10px !important;
  --mdc-chip-elevated-container-color: #0000001A !important;
  --mdc-chip-label-text-size: 17px !important;
  color: black;
}

.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.hide-mat-error {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-form-field .mdc-text-field--disabled {
  background-color: #F0F0F066;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  color: black !important;
  opacity: 1;
}

.filter-wrapper {
  .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    border-color: #e5e5e5 !important;
    background-color: white;
  }
  .mdc-floating-label {
    background-color: white;
  }
  .mat-mdc-form-field-infix, .mat-mdc-select-trigger {
    z-index: 1; // make sure the selected text is in front of the white background defined above
  }
}

.mat-mdc-select-arrow-wrapper {
  z-index: 1; // make sure it's in front of the white background defined above
  background-image: url('../assets/icon/expand-more.svg');
  background-size: 16px 16px; //set size of each icon
  background-position-x: -3px;
  background-position-y: 4px;
  height: 12px; // limit the height of the background images to only show the icon once (width is already limited)
  svg {
    opacity: 0; // hide original drop down icon
  }
}

app-paginator {
  .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    border: none;
  }

  .mdc-text-field--no-label {
    height: 48px !important;
  }

  .mat-mdc-form-field-infix {
    padding-top: 10px !important;
    padding-left: 2px;
  }
}


.mat-mdc-dialog-container {
  --mat-dialog-actions-alignment: flex-end;
  --mat-dialog-actions-padding: 20px;
}

.mat-mdc-dialog-title {
  --mat-dialog-headline-padding: 0 20px;

  mat-icon {
    cursor: pointer;
    width: 16px;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.hint-double-width {
  .mat-mdc-form-field-subscript-wrapper {
    width: 200%;
  }
}

.small-font-button {
  .mdc-button__label {
    font-size: 10px;
  }
}

.mat-mdc-checkbox-checked {
  .mdc-checkbox__background {
    background-color: var(--blue) !important;
  }
}

.mdc-checkbox__background {
  border-color: var(--blue) !important;
}

.mdc-checkbox {
  padding: 0 !important;
}

.mat-mdc-checkbox-touch-target {
  height: 24px !important;
  width: 24px !important;
}

.mdc-checkbox__checkmark {
  scale: 0.8;
}

.mdc-checkbox__background {
  position: initial !important;
}

.mdc-label {
  padding-left: 12px !important;
}

.header-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.limit-4-elements.mat-mdc-autocomplete-panel {
  max-height: 200px !important;
}

.archive-sort-header-select {
  .mat-mdc-select-value {
    width: unset;
    padding-right: 10px;
  }
}
