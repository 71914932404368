/* You can configure material theme */

@use '@angular/material' as mat;

@include mat.core();

$palette--primary: (
  50 : #ebebeb,
  100 : #cccccd,
  200 : #ababab,
  300 : #898989,
  400 : #6f6f70,
  500 : #565657,
  600 : #4f4f4f,
  700 : #454546,
  800 : #3c3c3c,
  900 : #2b2b2c,
  A100 : #ed5d5d,
  A200 : #ffebec,
  A400 : #ff1818,
  A700 : #fd0000,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$palette--accent: (
  50 : #fcfcfc,
  100 : #f8f8f8,
  200 : #f3f3f3,
  300 : #eeeeee,
  400 : #eaeaea,
  500 : #e6e6e6,
  600 : #e3e3e3,
  700 : #dfdfdf,
  800 : #dbdbdb,
  900 : #d5d5d5,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$palette--warn: (
  50 : #e0f2f8,
  100 : #b3dfed,
  200 : #80cae1,
  300 : #4db5d5,
  400 : #26a5cc,
  500 : #0095c3,
  600 : #008dbd,
  700 : #0082b5,
  800 : #0078ae,
  900 : #0067a1,
  A100 : #cbe9ff,
  A200 : #98d4ff,
  A400 : #65bfff,
  A700 : #4cb4ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$palette--warn: (
  50 : #fee5ea,
  100 : #fdbdcb,
  200 : #fc92a9,
  300 : #fa6686,
  400 : #f9456c,
  500 : #f82452,
  600 : #f7204b,
  700 : #f61b41,
  800 : #f51638,
  900 : #f30d28,
  A100 : #ffffff,
  A200 : #ffebec,
  A400 : #ffb8be,
  A700 : #ff9ea7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$app-primary: mat.define-palette($palette--primary, 500);
$app-accent: mat.define-palette($palette--accent, 500);
$app-warn: mat.define-palette($palette--warn, 500);
$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));
@include mat.all-component-themes($app-theme);